import { Button, Dropdown, Menu } from "antd";
import { IoIosArrowDown } from "react-icons/io";

const CustomDropDown = ({ items, text, filterMenu, selectedStatus }) => {
  return (
    <Dropdown
      trigger="click"
      overlay={
        <Menu>
          {filterMenu}
          {items?.map((item) => (
            <Menu.Item key={item.key}>{item.label}</Menu.Item>
          ))}
        </Menu>
      }
      placement="bottom"
      arrow
    >
      <Button className="status-filter-btn">
        <p className="tw-w-28 tw-truncate !tw-mb-0">{text}</p> <IoIosArrowDown />
      </Button>
    </Dropdown>
  );
};

export default CustomDropDown;
