import React, { useState, useEffect } from "react";
import { Modal, Col, Row } from "react-bootstrap";
import { AiOutlineSearch } from "react-icons/ai";
import "./store.css";
import { Button } from "antd";
import { SlReload } from "react-icons/sl";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import "bootstrap/dist/css/bootstrap.min.css";
import CustomButton from "../../components/Button";
import { apiClient } from "../../components/apiClient";
import { connect } from "react-redux";
import AddStore from "./AddStore";
import ViewStore from "./ViewStore";
import { Menu } from "antd";
import CustomDataTable from "../../components/DataTable";
import CustomDropDown from "../../components/CustomDropDown";
import LocationNegativeScreen from "../../components/NegativeScreens/Locations/LocationNegativeScreen";

const Stores = ({
  userId,
  accessToken,
  userProfile,
  sellerId,
  business,
  state,
}) => {
  let logUserId = userId;
  const [searchTerm, setSearchTerm] = useState("");
  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showAddStore, setShowAddStore] = useState(false);
  const [showViewStore, setShowViewStore] = useState(false);
  const [viewData, setViewData] = useState({});
  // const [isOpen, setOpen] = useState(false);
  const [visible, setVisible] = useState(false);
  const [activeModal, setActiveModal] = useState(false);
  const [layoutsId, setLayoutsId] = useState("");
  const [showPlusModal, setPlusModal] = useState(false);
  const handleShowAddStore = () => setShowAddStore(true);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const handleCloseAddStore = (val = null) => {
    if (val) getLocationList();
    setShowAddStore(false);
  };

  const handleShowViewStore = (val = null, visibleVal) => {
    if (val) {
      setVisible(visibleVal);
      setViewData(val);
      setShowViewStore(true);
    }
  };
  const handleStatusFilter = (status) => {
    const selectedStatusString = selectedStatus
      ? selectedStatus.toString()
      : null;
    const statusString = status.toString();

    setSelectedStatus(
      statusString === selectedStatusString ? null : statusString
    );
  };
  const handleCloseViewStore = (val = null) => {
    if (val) getLocationList();
    setShowViewStore(false);
  };
  const [selectedLocation, setSelectedLocation] = useState(null);

  const handleLocationFilter = (location) => {
    const selectedLocationString = selectedLocation
      ? selectedLocation.toString()
      : null;
    const locationString = location.toString();
    setSelectedLocation(
      locationString === selectedLocationString ? null : locationString
    );
  };

  const handleDeleteLayout = async (storeId) => {
    setIsLoading(true);
    try {
      const response = await apiClient(accessToken).post(`store/delete`, {
        id: storeId,
        userId: logUserId,
      });
      setIsLoading(false);
      setData((prevData) =>
        prevData.map((deactive) =>
          deactive.id === storeId ? { ...deactive, status: "2" } : deactive
        )
      );
    } catch (error) {
      console.log("error:", error);
    }
  };

  const handleDeleteModal = (param) => {
    // console.log(param, "parr");
    setPlusModal(true);
    setLayoutsId(param.id);
  };

  useEffect(() => {
    if (searchTerm.trim() === "") {
      const filteredData = data.filter(
        (row) =>
          (selectedLocation === null || row.name === selectedLocation) &&
          (selectedStatus === null || row.status === selectedStatus)
      );
      setFilterData(filteredData);
    } else {
      const filteredData = data.filter(
        (row) =>
          row.name &&
          row.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
          (selectedLocation === null || row.name === selectedLocation) &&
          (selectedStatus === null || row.status === selectedStatus)
      );
      setFilterData(filteredData);
    }
  }, [searchTerm, selectedLocation, selectedStatus, data]);

  // const clickToFlip = () => {
  //   setOpen(!isOpen);
  // };

  // const clearLocationFilter = () => {
  //   setSelectedLocation(null);
  // };

  const getLocationList = () => {
    setIsLoading(true);
    apiClient(accessToken)
      .get(`store/storelist/${sellerId}`)
      .then((res) => {
        setIsLoading(false);

        // Extract the data
        const locationData = res.data.data;

        // Sort the data alphabetically (assuming each item has a "name" property to sort by)
        const sortedData = locationData.sort((a, b) => {
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        });

        // Update state with sorted data
        setData(sortedData);
        setFilterData(sortedData);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };
  useEffect(() => {
    getLocationList();
  }, []);
  const clearFilters = () => {
    setSelectedLocation(null);
    setSelectedStatus(null);
  };

  const handleLayoutActive = async (storeId) => {
    try {
      setIsLoading(true);
      const response = await apiClient(accessToken).post(`store/active`, {
        id: storeId,
        userId: logUserId,
      });
      setIsLoading(false);
      setData((prevData) =>
        prevData.map((active) =>
          active.id === storeId ? { ...active, status: "1" } : active
        )
      );
    } catch (error) {
      console.log("error:", error);
    }
  };

  const handleActiveModal = async (param) => {
    setLayoutsId(param.id);
    setActiveModal(true);
  };

  // const handleDelete = async (id = null) => {
  //   if (id) {
  //     const result = window.confirm("Are you sure you want to Delete?");
  //     if (result) {
  //       let param = {
  //         id: id,
  //         userId: logUserId,
  //       };
  //       await apiClient(accessToken)
  //         .post("store/delete", param)
  //         .then(() => {
  //           const updatedData = data.map((item) => {
  //             if (item.id === id) {
  //               return { ...item, status: "2" };
  //             }
  //             return item;
  //           });

  //           setData(updatedData);
  //           setFilterData(updatedData);
  //         })
  //         .catch((err) => {
  //           console.log(err);
  //         });
  //     }
  //   }
  // };

  const columns = [
    {
      name: "Name",
      selector: (param) => param.name,
      sortable: true,
    },
    {
      name: "Address",
      selector: (param) => param.address,
      sortable: true,
    },

    {
      name: "No.Displays",
      selector: (param) => param.DisplayLocationCount,
      sortable: true,
    },
    {
      name: "Status",
      selector: (param) => param.status,
      format: (param) => {
        if (param.status == 1) {
          return <span className="statusTab activve">Active</span>;
        } else {
          return <span className="statusTab deactive">In Active</span>;
        }
      },
      sortable: true,
    },
    {
      name: "Action",
      cell: (param) => (
        <>
          <span
            title="Edit"
            style={{
              cursor: "pointer",
              padding: "5px",
              display: "inline-block",
              border: "1px solid #DFDFDF",
              color: "#666666",
              background: "#F0F0F0",
              borderRadius: "4px",
              marginRight: "5px", // Adjust the spacing between icons
            }}
            onClick={() => handleShowViewStore(param, false)}
          >
            <div
              style={{
                width: "24px",
                height: "24px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <AiOutlineEdit style={{ fontSize: "20px", color: "#666666" }} />
            </div>
          </span>
          {param.status === "1" ? (
            <span
              title="Delete"
              style={{
                cursor: "pointer",
                padding: "5px",
                display: "inline-block",
                border: "1px solid #DFDFDF",
                color: "#666666",
                background: "#F0F0F0",
                borderRadius: "4px",
                marginRight: "5px", // Adjust the spacing between icons
              }}
              onClick={() => handleDeleteModal(param, false)}
            >
              <div
                style={{
                  width: "24px",
                  height: "24px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <AiOutlineDelete style={{ fontSize: "20px" }} />
              </div>
            </span>
          ) : (
            <span
              title="Active"
              style={{
                cursor: "pointer",
                padding: "5px",
                display: "inline-block",
                border: "1px solid #DFDFDF",
                color: "#666666",
                background: "#F0F0F0",
                borderRadius: "4px",
              }}
              onClick={() => handleActiveModal(param, false)}
            >
              <div
                style={{
                  width: "24px",
                  height: "24px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <SlReload style={{ fontSize: "20px" }} />
              </div>
            </span>
          )}
        </>
      ),
    },
  ];
  const StatusFilterMenu = (
    <Menu onClick={({ key }) => handleStatusFilter(key)}>
      <Menu.Item key="1">Active</Menu.Item>
      <Menu.Item key="2">Inactive</Menu.Item>
    </Menu>
  );
  const items = filterData.map((eachLocation, index) => {
    return {
      key: `${index}`,
      label: (
        <Button
          key={index}
          style={{
            width: "100%",
            textAlign: "left",
            padding: "8px 12px",
            backgroundColor:
              selectedLocation === eachLocation.name
                ? "#1890ff"
                : "transparent",
            color: selectedLocation === eachLocation.name ? "#fff" : "#000",
            border: "0px solid #d9d9d9",
          }}
          onClick={() => handleLocationFilter(eachLocation.name)}
        >
          {eachLocation.name}
        </Button>
      ),
    };
  });

  return (
    <>
      <div className="displays-bg-container">
        <Row className="p-2">
          <Col md={6}>
            <h6>Location of {business?.businessName}</h6>
          </Col>
          <Col align="right" md={6} className="add-layout-search-container">
            <div className="search-input-container-lay">
              <AiOutlineSearch style={{ fontSize: "23px", marginTop: "2px" }} />
              <input
                type="search"
                className="search-input"
                placeholder="Search"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            <CustomButton
              disabledButton={false}
              btnType="button"
              BtnClassName="add-layout-buttons"
              BtnTxt={<>Add Location</>}
              ClickEvent={handleShowAddStore}
            />
          </Col>
        </Row>
        <div className="search-input-dropdown-container">
          {/* <Dropdown
            menu={{
              items,
            }}
            trigger="click"
            placement="bottom"
            arrow
          >
            <Button className={`status-filter-btn`} onClick={clickToFlip}>
              Location <IoIosArrowDown />
            </Button>
          </Dropdown> */}
          <CustomDropDown items={items} text={selectedLocation || "Location"} />

          <CustomDropDown
            filterMenu={StatusFilterMenu}
            selectedStatus={selectedStatus}
            text={
              selectedStatus === "1"
                ? "Active"
                : selectedStatus === "2"
                ? "In Active"
                : "Status"
            }
          />

          {/* <Dropdown trigger="click" overlay={StatusFilterMenu} placement="bottomLeft" arrow>
            <Button className="status-filter-btn">
              Status {selectedStatus ? <FaFilter /> : null}
            </Button>
          </Dropdown> */}

          {selectedLocation || selectedStatus ? (
            <Button className="clear-filter-btn" onClick={clearFilters}>
              X
            </Button>
          ) : null}
        </div>
        <hr className="horizontalline" />
        {isLoading ? (
          <Row>
            <Col md={12} align="center">
              <div className="loader"></div>
            </Col>
          </Row>
        ) : filterData.length === 0 ? (
          <LocationNegativeScreen handleShowAddStore={handleShowAddStore} />
        ) : (
          <CustomDataTable
            columns={columns}
            data={filterData}
            tableKey="storesTable"
            sellerId={sellerId}
          />

          // <div className="all-table-container">
          //   <DataTable
          //     title=""
          //     columns={columns}
          //     data={filterData}
          //     pagination
          //     customStyles={tableStyle}
          //     paginationRowsPerPageOptions={[5, 10, 15]}
          //     paginationPerPage={10}
          //   />
          // </div>
        )}
      </div>
      <AddStore
        showAddStore={showAddStore}
        handleCloseAddStore={handleCloseAddStore}
      />

      <ViewStore
        data={viewData}
        disableField={visible}
        showViewStore={showViewStore}
        handleCloseViewStore={handleCloseViewStore}
      />

      <Modal
        size="sm"
        animation
        show={showPlusModal}
        onHide={handleDeleteModal}
        aria-labelledby="contained-modal-title-vcenter"
        // centered
        backdrop="static"
      >
        <Modal.Header>
          <div style={{ width: "100%" }}>
            <h4 style={{ fontSize: "16px", textAlign: "center" }}>
              Do you want to deactivate it ?
            </h4>
          </div>
        </Modal.Header>
        <Modal.Body>
          <Row className="">
            <Col style={{ display: "flex", justifyContent: "space-around" }}>
              <CustomButton
                BtnTxt={<>Yes</>}
                BtnClassName="yes-modal-btn"
                ClickEvent={() => {
                  handleDeleteLayout(layoutsId);
                  setPlusModal(false);
                }}
              />
              <CustomButton
                BtnTxt={<>No</>}
                BtnClassName="yes-modal-btn"
                ClickEvent={() => setPlusModal(false)}
              />
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      <Modal
        size="sm"
        animation
        show={activeModal}
        onHide={handleActiveModal}
        aria-labelledby="contained-modal-title-vcenter"
        // centered
        backdrop="static"
      >
        <Modal.Header>
          <div style={{ width: "100%" }}>
            <h4 style={{ fontSize: "16px", textAlign: "center" }}>
              Do you want to Activate it ?
            </h4>
          </div>
        </Modal.Header>
        <Modal.Body>
          <Row className="">
            <Col style={{ display: "flex", justifyContent: "space-around" }}>
              <CustomButton
                BtnTxt={<>Yes</>}
                BtnClassName="yes-modal-btn"
                ClickEvent={() => {
                  handleLayoutActive(layoutsId);
                  setActiveModal(false);
                }}
              />
              <CustomButton
                BtnTxt={<>No</>}
                BtnClassName="yes-modal-btn"
                ClickEvent={() => setActiveModal(false)}
              />
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  accessToken: state.accessToken,
  userId: state.userProfile.userId,
  userProfile: state.userProfile,
  sellerId: state.sellerIdReducer,
  business: state.currentBusiness,
  ...state,
});
export default connect(mapStateToProps)(Stores);
