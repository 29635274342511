import React, { useState, useEffect, useRef, useCallback } from "react";
import axios from "axios";
import OriginalDataTable from "react-data-table-component";
import tableStyle from "./Datatablestyle";
import ResizeIcon from "../assets/resize.svg";

export default function CustomDataTable({
  columns = [],
  data = [],
  tableKey,
  sellerId,
}) {
  // Use a single state object to reduce rerenders
  const [tableState, setTableState] = useState({
    columnWidths: columns.map((col) => col.width || 200),
    hoveredColumn: null,
    isLoading: true,
  });

  const baseurl = process.env.REACT_APP_BASE_URL;

  console.log(baseurl);

  const tableRef = useRef(null);
  const debounceTimeout = useRef(null);
  const initialLoadComplete = useRef(false);

  // Memoized API calls
  const saveColumnWidths = useCallback(
    async (widths) => {
      try {
        await axios.post(
          `${baseurl}columnWidth/save-column-widths/${sellerId}/${tableKey}`,
          { columnWidths: widths }
        );
      } catch (error) {
        console.error("Error saving column widths:", error);
      }
    },
    [sellerId, tableKey]
  );

  // Initialize column widths once
  useEffect(() => {
    const fetchColumnWidths = async () => {
      if (initialLoadComplete.current) return;

      try {
        // First try to get from localStorage
        const localWidths = JSON.parse(
          localStorage.getItem(`columnWidths_${tableKey}`)
        );

        if (localWidths && Array.isArray(localWidths)) {
          setTableState((prev) => ({
            ...prev,
            columnWidths: localWidths,
            isLoading: false,
          }));
        }

        // Then fetch from API
        const response = await axios.get(
          `${baseurl}columnWidth/get-column-widths/${sellerId}/${tableKey}`
        );

        if (response.data.data?.columnWidths) {
          setTableState((prev) => ({
            ...prev,
            columnWidths: response.data.data.columnWidths,
            isLoading: false,
          }));
          localStorage.setItem(
            `columnWidths_${tableKey}`,
            JSON.stringify(response.data.data.columnWidths)
          );
        }
      } catch (error) {
        console.error("Error fetching column widths:", error);
        setTableState((prev) => ({ ...prev, isLoading: false }));
      }

      initialLoadComplete.current = true;
    };

    fetchColumnWidths();
  }, [sellerId, tableKey]);

  // Optimized resize handler
  const handleResize = useCallback(
    (index, newWidth) => {
      setTableState((prev) => {
        const updatedWidths = [...prev.columnWidths];
        updatedWidths[index] = Math.max(newWidth, 50);

        // Save to localStorage immediately
        localStorage.setItem(
          `columnWidths_${tableKey}`,
          JSON.stringify(updatedWidths)
        );

        // Debounce API call
        if (debounceTimeout.current) {
          clearTimeout(debounceTimeout.current);
        }
        debounceTimeout.current = setTimeout(() => {
          saveColumnWidths(updatedWidths);
        }, 1000); // Increased debounce time to reduce API calls

        return { ...prev, columnWidths: updatedWidths };
      });
    },
    [tableKey, saveColumnWidths]
  );

  // Memoized header renderer
  const renderHeader = useCallback(
    (col, index) => (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          position: "relative",
          boxSizing: "border-box",
          padding: "0 16px",
        }}
        onMouseEnter={() =>
          setTableState((prev) => ({ ...prev, hoveredColumn: index }))
        }
        onMouseLeave={() =>
          setTableState((prev) => ({ ...prev, hoveredColumn: null }))
        }
      >
        <span
          style={{
            flex: 1,
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            display: "flex",
            alignItems: "center",
          }}
        >
          {col.name}
        </span>
        {tableState.hoveredColumn === index && (
          <div
            style={{
              position: "absolute",
              right: 0,
              top: 0,
              bottom: 0,
              width: "16px",
              cursor: "col-resize",
              zIndex: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            onMouseDown={(e) => {
              e.preventDefault();
              e.stopPropagation();
              const startX = e.clientX;
              const startWidth = tableState.columnWidths[index];

              const doDrag = (dragEvent) => {
                dragEvent.preventDefault();
                const newWidth = startWidth + (dragEvent.clientX - startX);
                handleResize(index, newWidth);
              };

              const stopDrag = () => {
                document.removeEventListener("mousemove", doDrag);
                document.removeEventListener("mouseup", stopDrag);
              };

              document.addEventListener("mousemove", doDrag);
              document.addEventListener("mouseup", stopDrag);
            }}
            onClick={(e) => e.stopPropagation()}
          >
            <img
              src={ResizeIcon}
              alt="Resize"
              style={{ width: "12px", height: "12px" }}
            />
          </div>
        )}
      </div>
    ),
    [tableState.hoveredColumn, tableState.columnWidths, handleResize]
  );

  const resizedColumns = React.useMemo(
    () =>
      columns.map((col, index) => ({
        ...col,
        name: renderHeader(col, index),
        width: `${tableState.columnWidths[index]}px`,
        style: {
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        },
      })),
    [columns, renderHeader, tableState.columnWidths]
  );

  if (tableState.isLoading) {
    return <div className="loader"></div>;
  }

  return (
    <div ref={tableRef} className="all-table-container">
      {Array.isArray(data) &&
        data.length > 0 &&
        Array.isArray(columns) &&
        columns.length > 0 && (
          <OriginalDataTable
            title=""
            columns={resizedColumns}
            data={data}
            pagination
            customStyles={tableStyle}
            paginationRowsPerPageOptions={[5, 10, 15, 25, 50, 100]}
            paginationPerPage={10}
          />
        )}
    </div>
  );
}
