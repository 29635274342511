import React, { useState, useEffect } from "react";
import { Modal, Col, Row } from "react-bootstrap";
import { Button, Pagination } from "antd";
import { BsGrid } from "react-icons/bs";
import { SlReload } from "react-icons/sl";
import { AiOutlineEdit } from "react-icons/ai";
import { FaList } from "react-icons/fa";
import { AiOutlineSearch } from "react-icons/ai";
import "bootstrap/dist/css/bootstrap.min.css";
import CustomButton from "../../components/Button";
import { PiDotsThreeVerticalBold } from "react-icons/pi";
import { AiOutlineDelete } from "react-icons/ai";
import { apiClient } from "../../components/apiClient";
import { connect } from "react-redux";
import AddLayout from "./AddLayout";
import ViewLayout from "./ViewLayout";
import { useNavigate, useLocation } from "react-router-dom";
import { Routes as AppRoutes } from "../../routes";
import PopUpcarosal from "./PopUpcarosal";
import { Menu, Dropdown, Card } from "antd";
import CustomDataTable from "../../components/DataTable";
import CustomDropDown from "../../components/CustomDropDown";
import Cookies from "js-cookie";
import "./Layouts.css";
import { setImageFiles } from "../../redux/action";
import ReactPaginate from "react-paginate";
import LayoutsNegativeScreen from "../../components/NegativeScreens/Layouts/LayoutsNegativeScreen";
import { toast } from "sonner";
const Layout = ({
  userId,
  accessToken,
  userProfile,
  sellerId,
  business,
  state,
}) => {
  // console.log("userId", userId);
  // console.log("accessToken", accessToken);
  // console.log("state", state);
  let logUserId = userId;
  const [searchTerm, setSearchTerm] = useState("");
  const location = useLocation();
  const { id, name } = location?.state || {};
  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [showPlusModal, setPlusModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showAddLayout, setShowAddLayout] = useState(false);
  const [showViewLayout, setShowViewLayout] = useState(false);
  const [viewData, setViewData] = useState({});
  const [activeModal, setActiveModal] = useState(false);
  const [layoutsId, setLayoutsId] = useState("");
  const [visible, setVisible] = useState(false);
  const [layoutNameSelect, setLayoutName] = useState("");
  const [popViewLayoutdata, setpopViewLayoutdata] = useState({});
  const [showpopupcaro, setshowpopupcaro] = useState(false);
  const [displaypopupwithdata, setDisplaypopupwithdata] = useState(false);
  const [viewType, setViewType] = useState("list");
  const profilepic =
    "https://s3.amazonaws.com/creativetim_bucket/photos/264729/thumb.jpeg?1620116551";
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 6;

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const offset = currentPage * itemsPerPage;
  const currentPageData = filterData.slice(offset, offset + itemsPerPage);

  useEffect(() => {
    const LayoutpreferredView = Cookies.get("layoutpreferredView");
    if (LayoutpreferredView) {
      setViewType(LayoutpreferredView);
    }
  }, []);
  const handleGridView = () => {
    setViewType("grid");
    Cookies.set("layoutpreferredView", "grid", { expires: 365 });
  };

  const handleListView = () => {
    setViewType("list");
    Cookies.set("layoutpreferredView", "list", { expires: 365 });
  };
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 430); // Adjust the breakpoint according to your needs
    };

    handleResize(); // Initial check
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (isMobile) {
      setViewType("grid"); // Set grid view as default for mobile
    }
  }, [isMobile]);
  const navigate = useNavigate();

  const handleShowAddLayout = () => setShowAddLayout(true);
  const handleCloseAddLayout = (val = null) => {
    if (val) getLayoutList();
    setShowAddLayout(false);
  };

  const handleShowViewLayout = (param) => {
    navigate(AppRoutes.Layoutviews, {
      state: { id: param.id, name: param.name },
    });
    //console.log(param, "paarr");
  };

  const handleCloseViewLayout = (val = null) => {
    if (val) getLayoutList();
    setShowViewLayout(false);
  };

  const handleviewLayout = (param) => {
    navigate(AppRoutes.Layoutviews, { state: { id: param.id } });
  };
  const [selectedStatus, setSelectedStatus] = useState(null);

  const handleStatusFilter = (status) => {
    const selectedStatusString = selectedStatus
      ? selectedStatus.toString()
      : null;
    const statusString = status.toString();

    setSelectedStatus(
      statusString === selectedStatusString ? null : statusString
    );
  };
  const getLibraryIdsCount = (libraryIds) => {
    try {
      const parsedLibraryIds = JSON.parse(libraryIds);
      if (Array.isArray(parsedLibraryIds)) {
        return parsedLibraryIds.length;
      } else {
        return 0; // If it's not an array, return 0
      }
    } catch (error) {
      console.error("Error parsing libraryIds:", error);
      return 0; // Return 0 in case of an error
    }
  };

  const getRandomFileUrl = (libraryIds) => {
    const count = getLibraryIdsCount(libraryIds);
    try {
      const parsedLibraryIds = JSON.parse(libraryIds);
      if (Array.isArray(parsedLibraryIds) && count > 0) {
        const randomIndex = Math.floor(Math.random() * parsedLibraryIds.length);
        return parsedLibraryIds[randomIndex].fileUrl;
      } else {
        console.error("LibraryIds is not an array or is empty.");
      }
    } catch (error) {
      console.error("Error parsing libraryIds:", error);
    }
    return null;
  };
  const isVideoUrl = (url) => {
    const videoRegex = /\.(mp4|mov)$/i;
    return videoRegex.test(url);
  };

  const handleDeleteLayout = async (layoutsId) => {
    try {
      setIsLoading(true);
      const response = await apiClient(accessToken).post(`layout/delete`, {
        id: layoutsId,
        userId: logUserId,
      });
      setIsLoading(false);
      setData((prevData) =>
        prevData.map((deactive) =>
          deactive.id === layoutsId ? { ...deactive, status: "2" } : deactive
        )
      );
    } catch (error) {
      setIsLoading(false);
      console.log("error:", error);
    }
  };

  useEffect(() => {
    if (searchTerm.trim() === "") {
      const filteredByStatus = data.filter(
        (row) => selectedStatus === null || row.status === selectedStatus
      );
      setFilterData(filteredByStatus);
    } else {
      const filteredUserList = data.filter(
        (row) =>
          row.name &&
          row.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
          (selectedStatus === null || row.status === selectedStatus)
      );
      setFilterData(filteredUserList);
    }
  }, [searchTerm, selectedStatus, data]);

  const sortData = (data) => {
    return data.sort((a, b) =>
      a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
    );
  };

  const getLayoutList = () => {
    setIsLoading(true);
    const params = {
      ...(selectedStatus !== null && { status: selectedStatus }),
    };

    apiClient(accessToken)
      .get(`layout/layoutlist/${sellerId}`, { params })
      .then((res) => {
        setIsLoading(false);

        const layoutData = res.data.data;

        console.log("layoutData", layoutData);
        setData(sortData(layoutData));
        setFilterData(sortData(layoutData));

        localStorage.setItem(
          "layoutList",
          JSON.stringify(sortData(layoutData))
        );
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getLayoutList();
  }, []);

  // console.log(filterData);

  const handleDeleteModal = (param) => {
    //console.log(param, "parr");
    setPlusModal(true);
    setLayoutsId(param.id);
  };

  const handleDelete = async (id = null) => {
    if (id) {
      const result = window.confirm("Are you sure you want to Delete?");
      if (result) {
        let param = {
          id: id,
          userId: logUserId,
        };
        await apiClient(accessToken)
          .post("layout/delete", param)
          .then(() => {
            const updatedData = data.map((item) => {
              if (item.id === id) {
                return { ...item, status: "2" };
              }
              return item;
            });

            setData(updatedData);
            setFilterData(updatedData);
            setIsLoading(false);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  };

  const handleLayoutActive = async (layoutsId) => {
    try {
      setIsLoading(true);
      const response = await apiClient(accessToken).post(`layout/active`, {
        id: layoutsId,
        userId: logUserId,
      });
      setIsLoading(false);
      setData((prevData) =>
        prevData.map((active) =>
          active.id === layoutsId ? { ...active, status: "1" } : active
        )
      );
    } catch (error) {
      setIsLoading(false);
      console.log("error:", error);
    }
  };

  const handleActiveModal = async (param) => {
    setLayoutsId(param.id);
    setActiveModal(true);
  };

  const handlefetchLayoutdata = async (param) => {
    try {
      const result = await apiClient(accessToken).get(
        `/layoutModule/details/${param.id}`
      );
      setpopViewLayoutdata(result.data.data);
      setLayoutName(param.name);
      setshowpopupcaro(true);
    } catch (error) {
      console.error("Error fetching layout details:", error);
    }
  };

  const handleShowpopupcaro = () => {
    setDisplaypopupwithdata(true);
    setshowpopupcaro(true);
  };
  const handleClosepopupcaro = () => {
    setshowpopupcaro(false);
  };
  const items = [
    {
      key: "1",
      label: (
        <Button
          type={selectedStatus === "1" ? "primary" : "default"}
          onClick={() => handleStatusFilter("1")}
        >
          Active
        </Button>
      ),
    },
    {
      key: "2",
      label: (
        <Button
          type={selectedStatus === "2" ? "primary" : "default"}
          onClick={() => handleStatusFilter("2")}
        >
          Inactive
        </Button>
      ),
    },
  ];
  const StatusFilterMenu = (
    <Menu onClick={({ key }) => handleStatusFilter(key)}>
      <Menu.Item key="1">Active</Menu.Item>
      <Menu.Item key="2">Inactive</Menu.Item>
    </Menu>
  );
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: "numeric", month: "short", year: "numeric" };
    return date.toLocaleDateString("en-GB", options);
  };

  const columns = [
    // {
    //   name: "image",
    //   cell: (param) => (
    //     <Avatar src={getRandomFileUrl(param.libraryIds)} />
    //   ),
    //   sortable: true,
    // },
    {
      name: "Layout Name",
      selector: (param) => param.name,
      sortable: true,
    },
    {
      name: "No.of Displays",
      selector: (param) => param.DisplayLayoutcount,
      sortable: true,
    },
    {
      name: "Last Modified",
      selector: (param) =>
        param.modifiedAt
          ? formatDate(param.modifiedAt)
          : formatDate(param.createdAt),
      sortable: true,
    },
    {
      name: "Date Created",
      selector: (param) => formatDate(param.createdAt),
      sortable: true,
    },
    ...(viewType === "list"
      ? [
          {
            name: "Status",
            selector: (param) => param.status,
            format: (param) => {
              if (param.status == 1) {
                return (
                  <span
                    style={{ color: "white", backgroundColor: "green" }}
                    className="statusTab activve"
                  >
                    Active
                  </span>
                );
              } else {
                return (
                  <span
                    style={{ color: "white", backgroundColor: "red" }}
                    className="statusTab deactive"
                  >
                    In Active
                  </span>
                );
              }
            },
            sortable: true,
          },
        ]
      : []),

    {
      name: "Action",
      cell: (param) => (
        <>
          {/* Edit Icon */}
          <span
            title="Edit"
            style={{
              cursor: "pointer",
              padding: "5px",
              display: "inline-block",
              border: "1px solid #DFDFDF",
              color: "#666666",
              background: "#F0F0F0",
              borderRadius: "4px",
              marginRight: "5px", // Adjust the spacing between icons
            }}
            onClick={() => handleShowViewLayout(param, false)}
          >
            <div
              style={{
                width: "24px",
                height: "24px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <AiOutlineEdit style={{ fontSize: "20px", color: "#666666" }} />
            </div>
          </span>

          {/* Delete or Active Icon */}
          {param.status === "1" ? (
            <span
              title="Delete"
              style={{
                cursor: "pointer",
                padding: "5px",
                display: "inline-block",
                border: "1px solid #DFDFDF",
                color: "#666666",
                background: "#F0F0F0",
                borderRadius: "4px",
              }}
              onClick={() => handleDeleteModal(param, false)}
            >
              <div
                style={{
                  width: "24px",
                  height: "24px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <AiOutlineDelete style={{ fontSize: "20px" }} />
              </div>
            </span>
          ) : (
            <span
              title="Active"
              style={{
                cursor: "pointer",
                padding: "5px",
                display: "inline-block",
                border: "1px solid #DFDFDF",
                color: "#666666",
                background: "#F0F0F0",
                borderRadius: "4px",
              }}
              onClick={() => handleActiveModal(param, false)}
            >
              <div
                style={{
                  width: "24px",
                  height: "24px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <SlReload style={{ fontSize: "20px" }} />
              </div>
            </span>
          )}
        </>
      ),
    },
  ];

  const handleMenuClick = (action, layoutId) => {
    if (action === "edit") {
      handleShowViewLayout({ id: layoutId.id, name: layoutId.name });
    } else if (action === "delete") {
      handleDeleteLayout(layoutId.id);
    }
  };
  const confirmDelete = async (layoutIdToDelete) => {
    console.log("layoutIdToDelete", layoutIdToDelete);
    try {
      setIsLoading(true);
      await apiClient(accessToken).delete(`layout/delete`, {
        data: {
          id: layoutIdToDelete,
        },
      });
      setIsLoading(false);
      setData((prevData) =>
        prevData.filter((item) => item.id !== layoutIdToDelete)
      );
      setPlusModal(false);
      toast.success("Layout deleted successfully");
    } catch (error) {
      setIsLoading(false);
      console.log("error:", error);
      toast.error("Error deleting layout");
    }
  };
  return (
    <>
      <div className="displays-bg-container">
        <Row className="p-2">
          <Col md={6}>
            <h6>Layout of {business?.businessName} </h6>
          </Col>
          <Col align="right" md={6} className="add-layout-search-container">
            <div className="search-input-container-lay ">
              <AiOutlineSearch style={{ fontSize: "23px" }} />
              <input
                type="search"
                className="search-input"
                placeholder="Search"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            <CustomButton
              disabledButton={false}
              btnType="button"
              BtnClassName="add-layout-button"
              BtnTxt={<>Add Layout</>}
              ClickEvent={handleShowAddLayout}
            />
          </Col>
        </Row>
        <br />
        <div className="search-input-dropdown-containers">
          {viewType === "list" && (
            <CustomDropDown
              filterMenu={StatusFilterMenu}
              selectedStatus={selectedStatus}
              text={
                selectedStatus === "1"
                  ? "Active"
                  : selectedStatus === "2"
                  ? "In Active"
                  : "Status"
              }
            />
          )}
          {selectedStatus && (
            <Button
              className="clear-filter-btn"
              onClick={() => setSelectedStatus(null)}
            >
              X
            </Button>
          )}
          <Button
            className="default tw-flex tw-items-center poppins-font"
            onClick={handleListView}
            style={{ background: viewType === "list" ? "#DEEBFF" : "" }}
          >
            <FaList style={{ marginRight: "5px" }} /> List View
          </Button>
          <Button
            className="default tw-flex tw-items-center  poppins-font"
            onClick={handleGridView}
            style={{ background: viewType === "grid" ? "#DEEBFF" : "" }}
          >
            <BsGrid style={{ marginRight: "5px" }} /> Grid View
          </Button>
        </div>
        <hr className="horizontalline" />
        {isLoading ? (
          <Row>
            <Col md={12} align="center" className=" tw-flex tw-items-center ">
              <div className="loader"></div>
            </Col>
          </Row>
        ) : filterData.length === 0 ? (
          <LayoutsNegativeScreen />
        ) : (
          <>
            {/* View based on viewType */}
            {viewType === "list" ? (
              <CustomDataTable
                columns={columns}
                data={filterData}
                tableKey="layoutsTable"
                sellerId={sellerId}
              />
            ) : (
              <div className="images-list-container">
                {currentPageData.map((layout) => {
                  let count = 0;
                  let contentUrl = getRandomFileUrl(layout.libraryIds);
                  try {
                    count = getLibraryIdsCount(layout.libraryIds);
                  } catch (error) {
                    console.error(
                      `Error parsing libraryIds for layout ${layout.name}:`,
                      error
                    );
                  }

                  if (count === 0) {
                    contentUrl = require("../../assets/NoImg.png");
                  }

                  const isVideo = isVideoUrl(contentUrl);

                  return (
                    <Card
                      key={layout.id}
                      style={{
                        maxWidth: "100%",
                        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                      }}
                      className="image-card-container card"
                      title={
                        <div
                          style={{
                            display: "flex",
                            fontFamily: "Poppins",
                            justifyContent: "space-between",
                            fontWeight: "500",
                            alignItems: "center",
                            borderBottom: "none",
                          }}
                        >
                          <div>{`${layout.name} (${count})`}</div>
                          <Button
                            style={{ border: "none" }}
                            icon={
                              <PiDotsThreeVerticalBold
                                style={{ fontSize: "24px" }}
                              />
                            }
                            onClick={() =>
                              handleMenuClick("edit", {
                                id: layout.id,
                                name: layout.name,
                              })
                            }
                          />
                        </div>
                      }
                    >
                      {isVideo ? (
                        <video
                          src={contentUrl}
                          style={{
                            maxWidth: "100%",
                            borderRadius: "24px",
                            margin: "0 auto",
                            padding: "0 10px",
                          }}
                          controls
                        />
                      ) : (
                        <img
                          alt="layout"
                          src={contentUrl}
                          style={{
                            maxWidth: "100%",
                            borderRadius: "24px",
                            margin: "0 auto",
                            padding: "0 10px",
                          }}
                        />
                      )}
                      <Card.Meta
                        description={`Last Modified . ${formatDate(
                          layout.createdAt || layout.modifiedAt
                        )}`}
                        style={{ margin: "5px", fontFamily: "Poppins" }}
                      />
                    </Card>
                  );
                })}
              </div>
            )}
            {viewType === "grid" && (
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                breakLabel={"..."}
                pageCount={Math.ceil(filterData.length / itemsPerPage)}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageChange}
                containerClassName={"pagination"}
                activeClassName={"active"}
              />
            )}
          </>
        )}
      </div>

      <AddLayout
        showAddLayout={showAddLayout}
        handleCloseAddLayout={handleCloseAddLayout}
      />

      <ViewLayout
        data={viewData}
        disableField={visible}
        showViewLayout={showViewLayout}
        handleCloseViewLayout={handleCloseViewLayout}
      />
      <PopUpcarosal
        handleClosepopupcaro={handleClosepopupcaro}
        handleShowpopupcaro={handleShowpopupcaro}
        data={popViewLayoutdata}
        show={showpopupcaro}
        layoutNameSelect={layoutNameSelect}
      />

      <Modal
        size="sm"
        animation
        show={showPlusModal}
        onHide={handleDeleteModal}
        aria-labelledby="contained-modal-title-vcenter"
        // centered
        backdrop="static"
      >
        <Modal.Header>
          <div style={{ width: "100%" }}>
            <h4 style={{ fontSize: "16px", textAlign: "center" }}>
              Do you want to delete it ?
            </h4>
          </div>
        </Modal.Header>
        <Modal.Body>
          <Row className="">
            <Col style={{ display: "flex", justifyContent: "space-around" }}>
              <CustomButton
                BtnTxt={<>Yes</>}
                BtnClassName="yes-modal-btn"
                ClickEvent={() => {
                  confirmDelete(layoutsId);
                  setPlusModal(false);
                }}
              />
              <CustomButton
                BtnTxt={<>No</>}
                BtnClassName="yes-modal-btn"
                ClickEvent={() => setPlusModal(false)}
              />
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      <Modal
        size="sm"
        animation
        show={activeModal}
        onHide={handleActiveModal}
        aria-labelledby="contained-modal-title-vcenter"
        // centered
        backdrop="static"
      >
        <Modal.Header>
          <div style={{ width: "100%" }}>
            <h4 style={{ fontSize: "16px", textAlign: "center" }}>
              Do you want to Activate it ?
            </h4>
          </div>
        </Modal.Header>
        <Modal.Body>
          <Row className="">
            <Col style={{ display: "flex", justifyContent: "space-around" }}>
              <CustomButton
                BtnTxt={<>Yes</>}
                BtnClassName="yes-modal-btn"
                ClickEvent={() => {
                  handleLayoutActive(layoutsId);
                  setActiveModal(false);
                }}
              />
              <CustomButton
                BtnTxt={<>No</>}
                BtnClassName="yes-modal-btn"
                ClickEvent={() => setActiveModal(false)}
              />
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  accessToken: state.accessToken,
  userId: state.userProfile.userId,
  userProfile: state.userProfile,
  sellerId: state.sellerIdReducer,
  business: state.currentBusiness,

  ...state,
});
export default connect(mapStateToProps)(Layout);
